import React, { useState, useEffect } from "react";
import { AppLink } from "@base";
import {
  MobileHeader,
  LanguageToggle,
  Socials,
  IntentionAbout,
} from "@molecules";
import { useAppState } from "@state";
import { m } from "framer-motion";
import classNames from "classnames";
import toggleBodyScroll from "@utils/toggleBodyScroll";
import t from "@utils/t";
import useHeaderData from "@staticQueries/HeaderQuery";
import useFooterData from "@staticQueries/FooterQuery";
import LogoEn from "@svg/logo.svg";
import LogoFr from "@svg/logoFr.svg";

const Header = ({ locales, lang, pageLang, inverse, intention }) => {
  const [{ layout }, dispatch] = useAppState();
  // Mobile nav triggers
  const { hideNav, intentions: intentionsOpen } = layout;

  const [background, setBackground] = useState(inverse ? "viridian" : "coral");
  const [visibleImage, setVisibleImage] = useState(null);

  useEffect(() => {
    setBackground(inverse ? "viridian" : "coral");
  }, [inverse]);

  useEffect(() => {
    toggleBodyScroll(hideNav);

    if (!hideNav) {
      setBackground("black");
      document.documentElement.classList.add("is-locked");
    } else {
      setBackground(inverse ? "viridian" : "coral");
      document.documentElement.classList.remove("is-locked");
    }
  }, [hideNav]);

  useEffect(() => {
    if (typeof window !== "undefined") window.scrollTo(0, 0);
  }, [intentionsOpen]);

  // Defaults for SVG Hamburger Icon animation
  const iconStyles = {
    animate: hideNav ? "closed" : "open",
    stroke: "currentColor",
    strokeWidth: "2",
  };

  const { nav, subnav } = useHeaderData()[pageLang || lang || "en"];
  const { socials } = useFooterData()[pageLang || lang || "en"];

  const darkBackground = !intentionsOpen
    ? background === "black" || background === "viridian"
    : false;

  const homepage = pageLang !== "en" ? `/${pageLang}/` : "/";
  const intentionHomepage =
    pageLang !== "en" ? `${process.env.GATSBY_HP}/fr/` : process.env.GATSBY_HP;

  return (
    <header
      className={classNames("absolute z-50 h-14 w-full", {
        "text-white": darkBackground,
        "text-black": !darkBackground,
      })}
    >
      <div className="relative z-20 flex h-14 items-center justify-between gap-8 border-b-3 border-current p-2">
        <nav aria-label="Header navigation" className="flex gap-8">
          {!intention && (
            <button
              type="button"
              className="flex h-8 w-8 items-center justify-center place-self-center rounded-full border-2 border-current"
              aria-label="Toggle mobile navigation"
              onClick={() => {
                dispatch({
                  type: hideNav ? "showNav" : "hideNav",
                });
              }}
            >
              {/* Hamburger Menu with animated SVG */}
              <span className="icon flex place-self-center">
                <svg width="30" height="30" viewBox="0 0 30 30">
                  <m.path
                    {...iconStyles}
                    variants={{
                      closed: { d: "M30 10L0 10" },
                      open: { d: "M28 28L2 4" },
                    }}
                  />
                  <m.path
                    {...iconStyles}
                    variants={{
                      closed: { d: "M30 15L0 15" },
                      open: { d: "M28 4L2 28" },
                    }}
                  />
                  <m.path
                    {...iconStyles}
                    variants={{
                      closed: { d: "M30 20L0 20" },
                      open: { d: "M28 4L2 28" },
                    }}
                  />
                </svg>
              </span>
            </button>
          )}
          <AppLink
            to={intention ? intentionHomepage : homepage}
            className={classNames("flex h-9 items-center", {
              "md:ml-6": intention,
            })}
            ariaLabel={t("Equality Fund homepage", pageLang)}
          >
            {(pageLang === "en" || pageLang === "es") && (
              <span className="icon--fit-height h-9 overflow-visible">
                <LogoEn />
              </span>
            )}
            {pageLang === "fr" && (
              <span className="icon--fit-height h-11 overflow-visible">
                <LogoFr />
              </span>
            )}
          </AppLink>
        </nav>

        <div className="mr-0 flex items-center gap-8 md:mr-6">
          {intention && <IntentionAbout lang={pageLang} />}
          <LanguageToggle
            hideNav={hideNav}
            inverse={inverse}
            lang={lang}
            locales={locales}
            pageLang={pageLang}
            intention={
              intention || process.env.GATSBY_CRAFT_SITE_HANDLE !== "default"
            }
          />
          <div
            className={classNames({
              "hidden sm:block":
                intention || process.env.GATSBY_CRAFT_SITE_HANDLE !== "default",
            })}
          >
            <Socials
              socials={socials}
              backgroundState={[background, setBackground]}
            />
          </div>
        </div>
      </div>
      {!intention && (
        <MobileHeader
          nav={nav}
          subnav={subnav}
          backgroundState={[background, setBackground]}
          imageState={[visibleImage, setVisibleImage]}
        />
      )}
    </header>
  );
};

Header.defaultProps = {};

export default Header;
