import { useStaticQuery, graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";
// eslint-disable-next-line no-unused-vars
import ImageFragment from "@fragments/ImageFragment";

const query = graphql`
  query HeaderQuery {
    craft {
      en: globalSet(handle: "header", site: "default") {
        ... on Craft_header_GlobalSet {
          primaryLinks {
            ... on Craft_primaryLinks_primaryLink_BlockType {
              uid
              linkItem {
                url
                text
              }
              image {
                ...ImageFragment
              }
            }
          }
          subNav: linkList0 {
            ... on Craft_linkList0_linkitem_BlockType {
              uid
              linkItem {
                url
                text
              }
            }
          }
        }
      }
      en2022: globalSet(handle: "header", site: "annualReport2022en") {
        ... on Craft_header_GlobalSet {
          primaryLinks {
            ... on Craft_primaryLinks_primaryLink_BlockType {
              uid
              linkItem {
                url
                text
              }
              image {
                ...ImageFragment
              }
            }
          }
          subNav: linkList0 {
            ... on Craft_linkList0_linkitem_BlockType {
              uid
              linkItem {
                url
                text
              }
            }
          }
        }
      }
      fr: globalSet(handle: "header", site: "fr") {
        ... on Craft_header_GlobalSet {
          primaryLinks {
            ... on Craft_primaryLinks_primaryLink_BlockType {
              uid
              linkItem {
                url
                text
              }
              image {
                ...ImageFragment
              }
            }
          }
          subNav: linkList0 {
            ... on Craft_linkList0_linkitem_BlockType {
              uid
              linkItem {
                url
                text
              }
            }
          }
        }
      }
      fr2022: globalSet(handle: "header", site: "annualReport2022fr") {
        ... on Craft_header_GlobalSet {
          primaryLinks {
            ... on Craft_primaryLinks_primaryLink_BlockType {
              uid
              linkItem {
                url
                text
              }
              image {
                ...ImageFragment
              }
            }
          }
          subNav: linkList0 {
            ... on Craft_linkList0_linkitem_BlockType {
              uid
              linkItem {
                url
                text
              }
            }
          }
        }
      }
      es2022: globalSet(handle: "header", site: "annualReport2022es") {
        ... on Craft_header_GlobalSet {
          primaryLinks {
            ... on Craft_primaryLinks_primaryLink_BlockType {
              uid
              linkItem {
                url
                text
              }
              image {
                ...ImageFragment
              }
            }
          }
          subNav: linkList0 {
            ... on Craft_linkList0_linkitem_BlockType {
              uid
              linkItem {
                url
                text
              }
            }
          }
        }
      }
    }
  }
`;

const handles = [
  "annualReport2022en",
  "annualReport2022fr",
  "annualReport2022es",
];
const isNew = handles.includes(process.env.GATSBY_CRAFT_SITE_HANDLE);

const dataResolver = globalSet => {
  const { primaryLinks, subNav } = globalSet || {};
  return {
    nav: primaryLinks?.map((col, i) => {
      const colors = isNew
        ? ["black", "offwhite", "coral", "viridian"]
        : ["light-green", "coral", "offwhite", "viridian"];
      return {
        ...col?.linkItem,
        image: resolveImage(col?.image),
        color: colors[i],
      };
    }),
    subnav: subNav?.map(l => l?.linkItem),
  };
};

const useData = () => {
  const { craft } = useStaticQuery(query);
  const { en, fr, en2022, fr2022, es2022 } = craft;

  return {
    en: dataResolver(!isNew ? en : en2022),
    fr: dataResolver(!isNew ? fr : fr2022),
    es: dataResolver(!isNew ? {} : es2022),
  };
};

export default useData;
