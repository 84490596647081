import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query FooterQuery {
    craft {
      en: globalSet(handle: "footer", site: "default") {
        ... on Craft_footer_GlobalSet {
          # nav
          footerNav {
            ... on Craft_footerNav_column_BlockType {
              uid
              link: mainLink {
                text
                url
              }
              sublinks {
                ... on Craft_sublinks_BlockType {
                  uid
                  sublink {
                    text
                    url
                  }
                }
              }
            }
          }
          subNav: linkList0 {
            ... on Craft_linkList0_linkitem_BlockType {
              uid
              linkItem {
                url
                text
              }
            }
          }
          # content
          copyright: descriptor0
          charity: descriptor1
          socials {
            url
            platform
          }
          landHeading: heading0
          landCopy: descriptor2
          # content
          newsletterCTA: heading10
          downloadCTA: heading1
          downloadSubtitle: descriptor3
          downloadLinks: linkList1 {
            ... on Craft_linkList1_linkItem_BlockType {
              uid
              linkItem {
                url
                text
              }
            }
          }
        }
      }
      en2022: globalSet(handle: "footer", site: "annualReport2022en") {
        ... on Craft_footer_GlobalSet {
          # nav
          footerNav {
            ... on Craft_footerNav_column_BlockType {
              uid
              link: mainLink {
                text
                url
              }
              sublinks {
                ... on Craft_sublinks_BlockType {
                  uid
                  sublink {
                    text
                    url
                  }
                }
              }
            }
          }
          subNav: linkList0 {
            ... on Craft_linkList0_linkitem_BlockType {
              uid
              linkItem {
                url
                text
              }
            }
          }
          # content
          copyright: descriptor0
          charity: descriptor1
          socials {
            url
            platform
          }
          landHeading: heading0
          landCopy: descriptor2
          # content
          newsletterCTA: heading10
          downloadCTA: heading1
          downloadSubtitle: descriptor3
          downloadLinks: linkList1 {
            ... on Craft_linkList1_linkItem_BlockType {
              uid
              linkItem {
                url
                text
              }
            }
          }
        }
      }
      fr: globalSet(handle: "footer", site: "fr") {
        ... on Craft_footer_GlobalSet {
          # nav
          footerNav {
            ... on Craft_footerNav_column_BlockType {
              uid
              link: mainLink {
                text
                url
              }
              sublinks {
                ... on Craft_sublinks_BlockType {
                  uid
                  sublink {
                    text
                    url
                  }
                }
              }
            }
          }
          subNav: linkList0 {
            ... on Craft_linkList0_linkitem_BlockType {
              uid
              linkItem {
                url
                text
              }
            }
          }
          # content
          copyright: descriptor0
          charity: descriptor1
          socials {
            url
            platform
          }
          landHeading: heading0
          landCopy: descriptor2
          # content
          newsletterCTA: heading10
          downloadCTA: heading1
          downloadSubtitle: descriptor3
          downloadLinks: linkList1 {
            ... on Craft_linkList1_linkItem_BlockType {
              uid
              linkItem {
                url
                text
              }
            }
          }
        }
      }
      fr2022: globalSet(handle: "footer", site: "annualReport2022fr") {
        ... on Craft_footer_GlobalSet {
          # nav
          footerNav {
            ... on Craft_footerNav_column_BlockType {
              uid
              link: mainLink {
                text
                url
              }
              sublinks {
                ... on Craft_sublinks_BlockType {
                  uid
                  sublink {
                    text
                    url
                  }
                }
              }
            }
          }
          subNav: linkList0 {
            ... on Craft_linkList0_linkitem_BlockType {
              uid
              linkItem {
                url
                text
              }
            }
          }
          # content
          copyright: descriptor0
          charity: descriptor1
          socials {
            url
            platform
          }
          landHeading: heading0
          landCopy: descriptor2
          # content
          newsletterCTA: heading10
          downloadCTA: heading1
          downloadSubtitle: descriptor3
          downloadLinks: linkList1 {
            ... on Craft_linkList1_linkItem_BlockType {
              uid
              linkItem {
                url
                text
              }
            }
          }
        }
      }
      es2022: globalSet(handle: "footer", site: "annualReport2022es") {
        ... on Craft_footer_GlobalSet {
          # nav
          footerNav {
            ... on Craft_footerNav_column_BlockType {
              uid
              link: mainLink {
                text
                url
              }
              sublinks {
                ... on Craft_sublinks_BlockType {
                  uid
                  sublink {
                    text
                    url
                  }
                }
              }
            }
          }
          subNav: linkList0 {
            ... on Craft_linkList0_linkitem_BlockType {
              uid
              linkItem {
                url
                text
              }
            }
          }
          # content
          copyright: descriptor0
          charity: descriptor1
          socials {
            url
            platform
          }
          landHeading: heading0
          landCopy: descriptor2
          # content
          newsletterCTA: heading10
          downloadCTA: heading1
          downloadSubtitle: descriptor3
          downloadLinks: linkList1 {
            ... on Craft_linkList1_linkItem_BlockType {
              uid
              linkItem {
                url
                text
              }
            }
          }
        }
      }
    }
  }
`;

const handles = [
  "annualReport2022en",
  "annualReport2022fr",
  "annualReport2022es",
];
const isNew = handles.includes(process.env.GATSBY_CRAFT_SITE_HANDLE);

const dataResolver = globalSet => {
  const {
    footerNav,
    landCopy,
    landHeading,
    newsletterCTA,
    socials,
    subNav,
    charity,
    copyright,
    downloadCTA,
    downloadLinks,
    downloadSubtitle,
  } = globalSet || {};

  return {
    footer: footerNav?.map(col => ({
      ...col?.link,
      children: col?.sublinks?.map(l => l.sublink),
    })),
    copyright,
    socials,
    charity,
    acknowledge: {
      heading: landHeading,
      copy: landCopy,
    },
    download: {
      heading: downloadCTA,
      links: downloadLinks?.map(l => l.linkItem),
      emailSupport: downloadSubtitle,
    },
    underFooter: subNav?.map(l => l.linkItem),
    newsletterCTA,
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const { craft } = response;
  const { en, fr, en2022, fr2022, es2022 } = craft;

  return {
    en: dataResolver(!isNew ? en : en2022),
    fr: dataResolver(!isNew ? fr : fr2022),
    es: dataResolver(!isNew ? {} : es2022),
  };
};

export default useData;
